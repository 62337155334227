/*
 * Copyright 2025 pyamsoft
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import { atom } from "jotai";
import { ObjectGraph } from "../arch/ObjectGraph";
import { Attraction } from "../pages/roulette/model/Attraction";
import { DarkModePreference } from "./Dark";

export const DarkTheme = atom(DarkModePreference.matches);
export const DefaultObjectGraph = atom<ObjectGraph | undefined>(undefined);
export const ShowSplashScreen = atom(false);
export const AttractionLoading = atom(false);
export const AttractionList = atom<ReadonlyArray<Attraction>>([]);
export const AttractionLoadError = atom<Error | undefined>(undefined);
